@mixin desktop {
  @media (min-width: 901px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 576px)  and (max-width: 900px) {
    @content;
  }
}

@mixin phone-md {
  @media (min-width: 421px ) and (max-width: 575px) {
    @content;
  }
}

@mixin phone-xs {
  @media (max-width: 420px ) {
    @content;
  }
}


@mixin desktop-vertical {
  @media (min-height: 1400px) {
    @content;
  }
}

@mixin tablet-vertical {
  @media (min-height: 1100px)  and (max-height: 1399px) {
    @content;
  }
}

@mixin phone-md-vertical {
  @media (min-height: 800px ) and (max-height: 1099px) {
    @content;
  }
}

@mixin phone-xs-vertical {
  @media (min-height: 799px ) {
    @content;
  }
}

