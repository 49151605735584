@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;500;700&display=swap');

@import "ng-select.theme";
@import "ng-select-desktop.theme";
@import '~swiper/swiper-bundle';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1400px
) !global;

$input-box-shadow: none;
$input-focus-box-shadow: none;
$input-btn-focus-box-shadow: none;
$border-radius: 32px !global;

@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/grid';

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
@import '~ngx-toastr/toastr-bs4-alert';

@import "colors";
@import "common";
@import "mixins";


body {
  font-family: 'Montserrat', sans-serif !important;

  color: $color_text;

  @include phone-xs {
    font-size: 0.8rem;
  }

  @include phone-md {
    font-size: 0.9rem;
  }

  @include tablet {
    font-size: 0.9rem;
  }

  @include desktop {
    font-size: 1em;
  }

  line-height: 1;
}

h4 {
  font-size: calc(1em + 1vmin);

  @include tablet {
    font-size: 1.2rem;
  }
}

h3 {
  font-size: calc(1.3em + 1vmin);

  @include tablet {
    font-size: 1.5rem;
  }
}

.bazzarr-submit-button {
  margin: 0 auto;
  display: block;
  background: $color_mongoose;
  border-radius: 60px;
  padding: 12px 0;
  color: $color_white;
  font-weight: bold;
}

h2 {
  font-size: calc(1.8em + 1vmin);

  @include tablet {
    font-size: 2rem;
  }
}

.small {
  @include tablet {
    font-size: 0.8rem;
  }
  font-size: calc(0.60em + 1vmin);
}

.container-mini {

  max-width: 1200px;
  margin: 0 auto;
}

h3.title {
  font-weight: bold;
  font-size: 2.2em;
}

h4.title {
  font-weight: bold;
  font-size: 1.8em;
}

.empty-space-fill {
  height: 130px;
  width: 100%;
}

a.url {
  color: $color_black;
}

app-market, app-auth {
  min-height: 100vh;
  display: block;
  position: relative;
  padding-bottom: 1px;
}

.main-content{
  padding-bottom: $footer_height;
}

app-footer {
  //position: absolute;
  bottom: 0;
  width: 100%;
  height: $footer_height;
}
