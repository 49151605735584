@import "~src/app/@theme/styles/colors";
@import "~src/app/@theme/styles/mixins";

$footer_height: 350px;

$gap_sizes: 5, 10, 15, 20, 25, 30, 35, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120, 125, 130, 140, 150, 170, 190, 220, 240;


@each $size in $gap_sizes {
  //margin top
  .mt-#{$size} {
    margin-top: #{$size}px!important;
  }
  //margin bottom
  .mb-#{$size} {
    margin-bottom: #{$size}px!important;
  }
  //margin right
  .mr-#{$size} {
    margin-right: #{$size}px!important;
  }
  //margin left
  .ml-#{$size} {
    margin-left: #{$size}px!important;
  }

  //padding top
  .pt-#{$size} {
    padding-top: #{$size}px!important;
  }
  //padding bottom
  .pb-#{$size} {
    padding-bottom: #{$size}px!important;
  }
  //padding right
  .pr-#{$size} {
    padding-right: #{$size}px!important;
  }
  //padding left
  .pl-#{$size} {
    padding-left: #{$size}px!important;
  }
}


.radio-item {
  display: inline-block;
  position: relative;
  padding: 0 6px;
}

.radio-item input[type='radio'] {
  display: none;
}

.radio-item label {
  color: $color_mongoose;
  font-weight: normal;
}

.radio-item label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 2px solid $color_mongoose;
  background-color: transparent;

  @include desktop {
    width: 23px;
    height: 23px;
  }

}

.radio-item input[type=radio]:checked + label:after {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 9px;
  left: 10px;
  content: " ";
  display: block;
  background: $color_mongoose;
  @include desktop {
    width: 15px;
    height: 15px;
  }
}

.margin-auto {
  margin: 0 auto;
}


.pointer, .cursor {
  cursor: pointer;
}

[disabled] {
  cursor: not-allowed;
}

.toast-bottom-center {
  bottom: 15px;
}

.toast-info {
  background-image: none;
  color: $color_white;
  background-color: $color_brave_chair;
}

.toast-error {
  background-image: none;
  color: $color_white;
  background-color: $color_radical_red;
}

.toast-container.toast-bottom-center .ngx-toastr {
  padding: 0.75rem 1.25rem 0.75rem;
  text-align: center;
  width: 190px;

  @include desktop {
    width: 350px;
  }
}


button.calendar, button.calendar:active {
  width: 2rem;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAcCAYAAAAEN20fAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAEUSURBVEiJ7ZQxToVAEIY/YCHGxN6XGOIpnpaEsBSeQC9ArZbm9TZ6ADyBNzAhQGGl8Riv4BLAWAgmkpBYkH1b8FWT2WK/zJ8ZJ4qiI6XUI3ANnGKWBnht2/ZBDRK3hgVGNsCd7/ui+JkEIrKtqurLpEWaphd933+IyI3LEIdpCYCiKD6HcuOa/nwOa0ScJEnk0BJg0UTUWJRl6RxCYEzEmomsIlPU3IPW+grIAbquy+q6fluy/28RIBeRMwDXdXMgXLj/B2uimRXpui4D9sBeRLKl+1N+L+t6RwbWrZliTTTr1oxYtzVWiTQAcRxvTX+eJMnlUDaO1vpZRO5NS0x48sIwfPc87xg4B04MCzQi8hIEwe4bl1DnFMCN2zsAAAAASUVORK5CYII=') !important;
  background-repeat: no-repeat;
  background-size: 23px;
  background-position: center;

  @include tablet {
    width: 3rem!important;
  }

  border: none;
  outline: none;

  :focus {
    border: none;
    outline: none;
  }
}

.ngb-dp-month {
  width: 100%;

  :focus {
    border: none;
    outline: none;
  }
}

.ngb-dp-week {
  width: 100%;

  :focus {
    border: none;
    outline: none;
  }
}

.ngb-dp-day {
  width: 100% !important;
  height: 2rem !important;

  @include tablet {
    height: 3rem!important;
  }

  :focus {
    border: none;
    outline: none;
  }
}

.ngb-dp-day > div {
  width: 2rem !important;
  height: 2rem !important;
  line-height: 2rem !important;
  margin: 0 auto;
  border-radius: 50%;


  @include tablet {
    width: 3rem !important;
    height: 3rem !important;
    line-height: 3rem !important;
  }

  &.bg-primary {
    background-color: $color_mongoose!important;
    color: $color_white !important;
  }

  :focus {
    border: none;
    outline: none;
  }
}

.ngb-dp-today > div {
  color: $color_ocean_blue;
}

.ngb-dp-weekdays {
  padding-top: 10px;
}

.ngb-dp-weekday {
  width: 14.285714286% !important;
  color: $color_wolf_gray !important;
}

.btn-link{
  color: $color_black !important;
}

.ngb-datepicker {
  box-shadow: 0 0 10px 1px rgb(0 0 0 / 10%) !important;
  border-radius: 20px !important;
  border: none;
}

.ngb-dp-header {
  border-radius: 20px 20px 0 0 !important;
  padding-top: 35px !important;
}

.ngb-dp-month-name, .ngb-dp-header, .ngb-dp-month-name, .ngb-dp-weekdays {
  background-color: white !important;
}

:focus {
  outline-color: transparent;
}


