$color_text: #16162E;

$color_white: #ffffff;
$color_black: #000000;

$color_mongoose: #BBA37F;
$color_wild_sand: #f6f6f6;
$color_bon_jour: #EDE8EC;
$color_mild_grey: #DEDEDE;
$color_mine_shaft: #3A3A3A;
$color_alabaster: #D7D3D3;
$color_radical_red: #FA766E;
$color_pig_pink: #FDDEE4;
$color_wild_willow: #A3C966;
$color_olivine: #96BE6F;
$color_dark_gray: #6A6A6A;
$color_wolf_gray: #9C9C9C;
$color_silver_gray: #8B8B97;
$color_very_light_grey: #c8c8c8;
$color_white_smoke: #F3F3F3;
$color_night_rider: #333333;
$color_thunder: #1f1e1f;
$color_brave_chair: #393354;
$color_shade: #373153;
$color_carret_blue: #5373a4;
$color_ocean_blue: #3a56a0;
$color_sun_yellow: #FFC107;

$color_item_color: #FAFAFA;
$color_box_wrapper: #f6f7fb;
